import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

import Swiper from 'swiper'
import { Autoplay, Navigation, Thumbs, EffectFade } from 'swiper/modules'
import 'swiper/css'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

"use strict";

let lang = 'pl';

$(function() {
    lang = $('html').attr('lang');

    let lastScroll = 0;
    let lastScrollHomepage = 0;
    const header = $('header')[0];
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";

    $('.text-slider-container').each(function () {
        $(this).find('.btn-custom-settings').remove();
    });

    let windowHeight = $(window).height();
    let homepageTop = 0;
    let decorTop = 0;
    let videoTop = 0;
    let homepageAnimationDownInProgress = false;
    let homepageAnimationUpInProgress = false;

    if ($('#homepage').length > 0) {
        homepageTop = $('#homepage').offset().top;
        decorTop = $('#decor').height();
        videoTop = $('#homepage .video-animate-container .video-container video').offset().top;
    }

    if ($('#homepage').length > 0) {
        if ($(window).innerWidth() >= 992) {
            $(window).on('scroll init', function () {
                let progress = Math.min($(window).scrollTop() / $('.video-container').offset().top, 1);
                $('.animated-cover-left').css('transform', `translateX(${-20 - progress * 80}%)`);
                $('.animated-cover-right').css('transform', `translateX(${120 + progress * 80}%)`);
                $('.video-container video').css({
                    height: `${80 + progress * 20}%`,
                    marginTop: `${-24 + progress * 30}rem`,
                });
            }).trigger('init');

            let animationInProgress = false;
            window.addEventListener("wheel", function (e) {
                if (animationInProgress) {
                    e.preventDefault();
                    return;
                }
                let startPosition = 0;
                let stopPosition = homepageTop + decorTop + 16;
                let middlePosition = (startPosition + stopPosition) / 2;
                let newPosition = $(window).scrollTop() + e.deltaY;
                if (newPosition >= startPosition && newPosition <= middlePosition && e.deltaY > 0) {
                    e.preventDefault();
                    animationInProgress = true;
                    $('html, body').animate({scrollTop: stopPosition}, 1000, () => animationInProgress = false);

                    setTimeout(function () {
                        $('#homepage .mute-icon').addClass('visible');
                        $('#homepage .unmute-icon').addClass('visible');
                    }, 800);
                } else if (newPosition <= stopPosition && newPosition >= middlePosition && e.deltaY < 0) {
                    e.preventDefault();
                    animationInProgress = true;
                    $('#homepage .mute-icon').removeClass('visible');
                    $('#homepage .unmute-icon').removeClass('visible');
                    $('html, body').animate({scrollTop: startPosition}, 1000, () => animationInProgress = false);
                }
            }, {passive: false});
        }
    }


    $(window).on('scroll', function(e) {
        const $window = $(window);

        if ($window.innerWidth() >= 992) {
            if ($('#projects').length > 0) {
                const $elem = $('#projects .projects-container');

                const docViewTop = $window.scrollTop();
                const docViewBottom = docViewTop + $window.height();
                const elemTop = $elem.offset().top;
                if (elemTop < docViewBottom) {
                    $('#projects .projects-container .image-box').addClass('load');
                }
            }

            if ($('#articles').length > 0) {
                const $elem = $('#articles .news-container');

                const docViewTop = $window.scrollTop();
                const docViewBottom = docViewTop + $window.height();
                const elemTop = $elem.offset().top;
                if (elemTop < docViewBottom) {
                    $('#articles .news-container .image-box').addClass('load');
                }
            }

            if ($('#about-us').length > 0) {
                const $elem = $('#about-us .about-us-container');

                const docViewTop = $window.scrollTop();
                const docViewBottom = docViewTop + $window.height();
                const elemTop = $elem.offset().top;
                if (elemTop < docViewBottom) {
                    $('#about-us .about-us-container .image-box').addClass('load');
                }
            }

            if ($('#homepage').length > 0) {
                /*$('main').on('mousewheel DOMMouseScroll wheel', function(e) {
                    if (homepageAnimationDownInProgress || homepageAnimationUpInProgress) {
                        e.preventDefault();
                        e.stopPropagation();

                        return false;
                    }
                });*/

                


                /*const currentScrollVideo = $(window).scrollTop();

                if (currentScrollVideo > lastScrollHomepage) {
                    // down
                    if (currentScrollVideo > 100 && currentScrollVideo < (homepageTop + decorTop - 5) && !$('#homepage').hasClass('animated') && !homepageAnimationUpInProgress) {
                        homepageAnimationDownInProgress = true;

                        $('#homepage').addClass('animated');
                        $('.animated-cover-right').addClass('load');
                        $('.animated-cover-left').addClass('load');
                        $('#homepage .video-animate-container .video-container video').addClass('load');
                        // $('#homepage .video-animate-container .video-container video').animate({
                        //     height: '100%',
                        //     marginTop: '6rem'
                        // }, { duration: 1000, queue: false });
                        $('html, body')
                            .animate({
                                scrollTop: homepageTop + decorTop + 16
                            }, { duration: 1000, queue: false });
                        setTimeout(function () {
                            $('#homepage .mute-icon').addClass('visible')
                            $('#homepage .unmute-icon').addClass('visible')
                        }, 800);
                        setTimeout(function () {
                            homepageAnimationDownInProgress = false;
                        }, 1500);
                    }

                } else if (
                    currentScrollVideo < lastScrollHomepage
                ) {
                    // up
                    if (currentScrollVideo < videoTop - (windowHeight * 0.15) && $('#homepage').hasClass('animated') && !homepageAnimationDownInProgress) {
                        homepageAnimationUpInProgress = true;
                        $('#homepage').removeClass('animated');
                        $('.animated-cover-right').removeClass('load');
                        $('.animated-cover-left').removeClass('load');
                        $('#homepage .mute-icon').removeClass('visible')
                        $('#homepage .unmute-icon').removeClass('visible')
                        $('#homepage .video-animate-container .video-container video').removeClass('load');
                        // $('#homepage .video-animate-container .video-container video').animate({
                        //     height: '80%',
                        //     marginTop: '-24rem'
                        // }, { duration: 1000, queue: false });
                        $('html, body')
                            .animate({
                                scrollTop: $('body').offset().top
                            }, { duration: 1000, queue: false });
                        setTimeout(function () {
                            homepageAnimationUpInProgress = false;
                        }, 1500);
                    }
                }

                lastScrollHomepage = currentScrollVideo;*/
            }
        }

        if (header) {
            const currentScroll = $(window).scrollTop();
            if (currentScroll <= 50) {
                header.classList.remove(scrollUp);
                return;
            }

            if (currentScroll > lastScroll && !header.classList.contains(scrollDown)) {
                // down
                header.classList.remove(scrollUp);
                header.classList.add(scrollDown);
            } else if (
                currentScroll < lastScroll &&
                header.classList.contains(scrollDown)
            ) {
                // up
                header.classList.remove(scrollDown);
                header.classList.add(scrollUp);
            }

            lastScroll = currentScroll;
        }
    });

    if (!$('#snippet-items').length) {
        $('.text-slider-container').each(function () {
            $(this).find('.slider-items-container').each(function () {
                const textColor = $(this).find('.slide-item h2').css('color');
                const itemHtml = $(this).find('.slide-item')[0].outerHTML.replaceAll('&amp;line&amp;', `<div style="border-top: 3px solid ${textColor}"></div>`);
                $(this).find('.slide-item').html($(this).find('.slide-item')[0].innerHTML.replaceAll('&amp;line&amp;', `<div style="border-top: 3px solid ${textColor}"></div>`));

                for (let i = 0; i < 4; i++) {
                    $(this).append(itemHtml);
                }

                $(this).closest('.items-slider').append($(this).closest('.items-slider').html());

                $(this).closest('.items-slider').addClass('loaded');
            });
        });
    }

    $('.title-line').each(function () {
        $(this).addClass('loaded');
    });

    $('video').each(function (i, video) {
        const autoplay = $(this).attr('autoplay');
        
        if (autoplay !== 'undefined' && autoplay !== undefined && autoplay !== false) {
            video.playinline = true;
            if (video.paused) {
                const promise = video.play();

                if (promise !== undefined) {
                    promise.then(() => {
                        // Autoplay started
                    }).catch(error => {
                        // Autoplay was prevented.
                        video.muted = true;
                        video.play();
                    });
                }
            }

            $(video).closest('.video-container').find('.play-icon').addClass('d-none');
        }
    });

    if ($('#homepage').length > 0) {
        $(window).on('resize', function () {
            if ($(this).innerWidth() >= 992 && $('.video-container').find('video').hasClass('mobile-video')) {
                $videoElem.attr('src', $videoElem.attr('src-desktop'));
                $videoElem.removeClass('mobile-video');
                $videoElem.addClass('desktop-video');
                $videoElem[0].load();
            } else if ($(this).innerWidth() < 992 && $('.video-container').find('video').hasClass('desktop-video')) {
                $videoElem.attr('src', $videoElem.attr('src-mobile'));
                $videoElem.removeClass('desktop-video');
                $videoElem.addClass('mobile-video');
                $videoElem[0].load();
            }
        });

        const $videoElem = $('.video-container').find('video');
        if ($(window).innerWidth() >= 992) {
            $videoElem.attr('src', $videoElem.attr('src-desktop'));
            $videoElem.addClass('desktop-video');
            $videoElem[0].load();
        } else {
            $videoElem.attr('src', $videoElem.attr('src-mobile'));
            $videoElem.addClass('mobile-video');
            $videoElem[0].load();
        }
    }

    $('#lang-switch').on('change', function () {
        if ($(this).is(':checked')) {
            $('label[for="lang-switch"]').addClass('checked');
        } else {
            $('label[for="lang-switch"]').removeClass('checked');
        }

        window.location.href = $(this).data('url');
    });

    const newsSwiper = new Swiper('#news-slider .swiper', {
        spaceBetween: 0,
        slidesPerView: 'auto',
        loop: true,
    });

    const projectsSwiper = new Swiper('#projects-slider .swiper', {
        spaceBetween: 0,
        slidesPerView: 'auto',
        loop: true,
    });

    if ($('#projects').length > 0) {
        $('#projects h2').html($('#projects h2').html().replaceAll('<br>', '<br class="d-none d-xl-block">'));

        const lineCount = $(window).innerWidth() >= 1200 ? 3 : 2;
        let line = 1;
        let key = 0;

        $('.projects-container .image-box').each(function () {
            if (key === 0) {
                $(this).attr('data-aos-delay', 0);
            } else if(key === 1) {
                $(this).attr('data-aos-delay', 250);
            } else {
                $(this).attr('data-aos-delay', 500);
            }

            key++;

            if (key % lineCount === 0) {
                line++;
                key = 0;
            }
        });

        let categoriesWidth = 0;

        if ($(window).innerWidth() >= 992) {
            $('.category-box').each(function () {
                categoriesWidth += parseFloat($(this).innerWidth()) + 48;
            });
        } else {
            $('.category-box').each(function () {
                categoriesWidth += parseFloat($(this).innerWidth()) + 16;
            });
        }

        if (categoriesWidth >= $('.categories-container').innerWidth()) {
            $('.categories-container').addClass('justify-content-start');
        } else {
            $('.categories-container').removeClass('justify-content-start');
        }

        $(window).on('resize', function () {
            let categoriesWidthAfterResize = 0;

            if ($(window).innerWidth() >= 992) {
                $('.category-box').each(function () {
                    categoriesWidthAfterResize += parseFloat($(this).innerWidth()) + 48;
                });
            } else {
                $('.category-box').each(function () {
                    categoriesWidthAfterResize += parseFloat($(this).innerWidth()) + 16;
                });
            }

            if (categoriesWidthAfterResize >= $('.categories-container').innerWidth()) {
                $('.categories-container').addClass('justify-content-start');
            } else {
                $('.categories-container').removeClass('justify-content-start');
            }
        });
    }

    if ($('#about-us').length > 0) {
        const lineCount = $(window).innerWidth() >= 1200 ? 3 : 2;
        let line = 1;
        let key = 0;

        $('.about-us-container .image-box').each(function () {
            if (key === 0) {
                $(this).attr('data-aos-delay', 0);
            } else if(key === 1) {
                $(this).attr('data-aos-delay', 250);
            } else {
                $(this).attr('data-aos-delay', 500);
            }

            key++;

            if (key % lineCount === 0) {
                line++;
                key = 0;
            }
        });
    }

    if ($('#articles').length > 0) {
        const lineCount = $(window).innerWidth() >= 1200 ? 2 : 1;
        let line = 1;
        let key = 0;

        $('.news-container .image-box').each(function () {
            if (key === 0) {
                $(this).attr('data-aos-delay', 0);
            } else if(key === 1) {
                $(this).attr('data-aos-delay', 250);
            }

            key++;

            if (key % lineCount === 0) {
                line++;
                key = 0;
            }
        });
    }

    $('body').on('click', '.projects-container .image-hover, .homepage-projects-container .image-hover, #projects-slider .project-image-container', function () {
        const url = $(this).attr('data-url');
        window.location.href = `${url}`;
    });

    $('body').on('click', '.news-container .image-box, .homepage-news-container .image-box, #news-slider .image-box', function () {
        const url = $(this).data('url');
        window.location.href = `${url}`;
    });

    $('.play-icon').on('click', function () {
        const video = $(this).closest('.video-container').find('video')[0];
        $(this).addClass('d-none');

        video.play();
    });

    $('.unmute-icon').on('click', function () {
        const video = $(this).closest('.video-container').find('video')[0];
        $(this).addClass('d-none');
        $('.mute-icon').removeClass('d-none');

        video.muted = true;
    });

    $('.mute-icon').on('click', function () {
        const video = $(this).closest('.video-container').find('video')[0];
        $(this).addClass('d-none');
        $('.unmute-icon').removeClass('d-none');

        video.muted = false;
    });

    $('.video-container video').on('click', function () {
        const video = this;

        if (!video.paused) {
            video.pause();
            $(video).closest('.video-container').find('.play-icon').removeClass('d-none');
        }
    });

    $('.video-container video').each(function () {
        const video = this;

        video.addEventListener('pause', () => {
            $(video).closest('.video-container').find('.play-icon').removeClass('d-none');
        });

        video.addEventListener('play', () => {
            $(video).closest('.video-container').find('.play-icon').addClass('d-none');
        });

        if (video.paused) {
            $(video).closest('.video-container').find('.play-icon').removeClass('d-none');
        }
    });

    if ($(window).innerWidth() < 992) {
        setTimeout(function () {
            $('#projects .projects-container .image-box').addClass('load');

            $('#articles .news-container .image-box').addClass('load');

            $('#about-us .about-us-container .image-box').addClass('load');
        }, 1000);
    }

    $('#projects .category-box').on('click', function (e) {
        e.preventDefault();
        $('#projects .category-box').removeClass('active');
        $(this).addClass('active');

        setTimeout(function () {
            $('.projects-container .image-box').removeClass('load');
        }, 690);

        $('.projects-container .image-box').addClass('out');

        let data = {
            _token: $('.projects-container').attr('data-token'),
            category: $(this).data('category'),
            offset: 0,
            limit: 15,
        };

        //ajax
        const currentLang = lang === 'pl' ? '' : `/${lang}`
        const url = `${currentLang}/projekty/lista`;
        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const projects = response.responseJSON;

                if (projects.length < 3) {
                    $('#more-projects').addClass('hidden');
                } else {
                    $('#more-projects').removeClass('hidden');
                }

                let projectsHtml = '';

                projects.forEach(function (project, key) {
                    const title2 = project.title2 ? `<p>${project.title2}</p>` : '';
                    const title3 = project.title3 ? `<p>${project.title3}</p>` : '';
                    projectsHtml += `
                    <div class="image-box"
                        data-aos="fade-up"
                        data-aos-delay="0"
                        data-aos-duration="500"
                    >
                        <img src="${project.image}" alt=""/>
                        <div class="image-hover text-start" data-url="${project.url}">
                            <p class="small">${project.client}</p>
                            <p>${project.title1}</p>
                            ${title2}
                            ${title3}
                        </div>
                    </div>
                `;
                });

                $('.projects-container').html(projectsHtml);

                setTimeout(function () {
                    $('.projects-container .image-box').addClass('load');
                }, 750);
            }
        });
    });

    $('#more-projects').on('click', function () {
        let data = {
            _token: $('.projects-container').attr('data-token'),
            category: $('#projects .category-box.active').data('category'),
            offset: $('.projects-container .image-box').length,
            limit: 15,
        };

        //ajax
        const url = '/projekty/lista';
        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const projects = response.responseJSON;

                if (projects.length < 15) {
                    $('#more-projects').addClass('hidden');
                } else {
                    $('#more-projects').removeClass('hidden');
                }

                let projectsHtml = '';
                let aosProjectDelay = 0;
                const lineCount = $(window).innerWidth() >= 1200 ? 3 : 2;
                let line = 1;
                let index = 0;

                projects.forEach(function (project, key) {
                    if (index === 0) {
                        aosProjectDelay = 0;
                    } else if(index === 1) {
                        aosProjectDelay = 250;
                    } else {
                        aosProjectDelay = 500;
                    }

                    index++;

                    if (index % lineCount === 0) {
                        line++;
                        index = 0;
                    }

                    const title2 = project.title2 ? `<p>${project.title2}</p>` : '';
                    const title3 = project.title3 ? `<p>${project.title3}</p>` : '';
                    projectsHtml += `
                    <div class="image-box"
                        data-aos="fade-up"
                        data-aos-delay="${aosProjectDelay}"
                        data-aos-duration="500"
                    >
                        <img src="${project.image}" alt=""/>
                        <div class="image-hover text-start" data-url="${project.url}">
                            <p class="small">${project.client}</p>
                            <p>${project.title1}</p>
                            ${title2}
                            ${title3}
                        </div>
                    </div>
                `;
                });

                $('.projects-container').append(projectsHtml);

                setTimeout(function () {
                    $('.projects-container .image-box:not(.load)').addClass('load');
                    AOS.refresh();
                }, 500);
            }
        });
    });

    $('#more-news').on('click', function () {
        let data = {
            _token: $('.news-container').attr('data-token'),
            offset: $('.news-container .image-box').length,
            limit: 4,
        };

        //ajax
        const url = '/artykuly/lista';
        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const news = response.responseJSON;

                if (news.length < 15) {
                    $('#more-news').addClass('hidden');
                } else {
                    $('#more-news').removeClass('hidden');
                }

                let newsHtml = '';
                const lineCount = $(window).innerWidth() >= 1200 ? 2 : 1;
                let line = 1;
                let index = 0;
                let aosDelay = 0;

                news.forEach(function (item, key) {
                    if (index === 0) {
                        aosDelay = 0;
                    } else if(index === 1) {
                        aosDelay = 250;
                    }

                    index++;

                    if (index % lineCount === 0) {
                        line++;
                        index = 0;
                    }

                    newsHtml += `
                        <div class="image-box"
                            data-aos="fade-up"
                            data-aos-delay="${aosDelay}"
                            data-aos-duration="500" 
                            data-url="${item.url}"
                         >
                            <img src="${item.image}" alt=""/>
                            <div class="text-start">
                                <img src="${item.decor}" alt="cięcie" class="decor-white-top d-none d-lg-block"/>
                                <p class="small">${item.startDate}</p>
                                <p class="mt-2 mt-lg-5">${item.lead}</p>
                            </div>
                        </div>
                    `;
                });

                $('.news-container').append(newsHtml);

                setTimeout(function () {
                    $('.news-container .image-box:not(.load)').addClass('load');

                    AOS.refresh();
                }, 500);
            }
        });
    });

    $('#more-employees').on('click', function () {
        let data = {
            _token: $('.about-us-container').attr('data-token'),
            offset: $('.about-us-container .image-box').length,
            limit: 15,
        };

        //ajax
        const url = '/pracownicy/lista';
        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const employees = response.responseJSON;

                if (employees.length < 18) {
                    $('#more-employees').addClass('hidden');
                } else {
                    $('#more-employees').removeClass('hidden');
                }

                let employeesHtml = '';
                let aosEmployeeDelay = 0;
                const lineCount = $(window).innerWidth() >= 1200 ? 3 : 2;
                let line = 1;
                let index = 0;

                employees.forEach(function (employee, key) {
                    if (index === 0) {
                        aosEmployeeDelay = 0;
                    } else if(index === 1) {
                        aosEmployeeDelay = 250;
                    } else {
                        aosEmployeeDelay = 500;
                    }

                    index++;

                    if (index % lineCount === 0) {
                        line++;
                        index = 0;
                    }
                    employeesHtml += `
                    <div class="image-box"
                        data-aos="fade-up"
                        data-aos-delay="${aosEmployeeDelay}"
                        data-aos-duration="500"
                    >
                        <img src="${employee.image}" alt=""/>
                        <div class="image-hover text-start">
                            <p>${employee.firstname}</p>
                            <p>${employee.lastname}</p>
                            <p class="small">${employee.position}</p>
                        </div>
                    </div>
                `;
                });

                $('.about-us-container').append(employeesHtml);

                setTimeout(function () {
                    $('.about-us-container .image-box:not(.load)').addClass('load');

                    AOS.refresh();
                }, 500);
            }
        });
    });

    $('.slider').each(function () {
        const slidesToShow = $(this).data('slides-to-show');
        const arrows = $(this).data('arrows');
        const dots = $(this).data('dots');

        $(this).slick({
            slidesToShow: slidesToShow,
            arrows: arrows,
            dots: dots,
            draggable: $(this).closest('#workpage').length === 0
        });
    });

    $('div[data-news="true"]').each(function () {
        const self = this;
        const url = '/category/news';
        let data = {
            category: $(self).data('categoryid'),
        };

        if ($('#workpage').length === 0) {
            data.lang = lang;
        }

        $.ajax({
            url: url,
            type: 'GET',
            data: data,
            complete: function (response) {
                const news = response.responseJSON;

                $(self).find('.news-item').each(function (key) {
                    if (news[key]) {
                        $($(this).find('.photo')).css('background-image', `url(${news[key].image})`);
                        $($(this).find('.details h5')).text(news[key].title);
                        $($(this).find('.details h4')).text(news[key].lead);
                        $(this).removeClass('d-none');

                        $(this).removeClass('loading-skeleton');
                    }
                });
            }
        });
    });

    $('.contact-section .btn-submit').on('click', function (e) {
        e.preventDefault();

        const form = $(this).closest('form');
        const formData = form.serializeArray();
        const agree = formData.find(function (item) { return item.name === 'agree' });

        if (agree) {
            agree.value = $('input[name="agree"]').is(':checked')
        } else {
            formData.push({
                name: 'agree',
                value: $('input[name="agree"]').is(':checked')
            });
        }

        if (checkFormValidation(formData)) {
            formData.push(
                {
                    name: 'subject',
                    value: `Kontakt ze strony: ${window.location.href}`
                },
                {
                    name: 'sendTo',
                    value: form.attr('data-email')
                });

            $.ajax({
                url: form.attr('action'),
                type: 'POST',
                data: formData,
                complete: function (response) {
                    if (response.responseJSON.status === 'success') {
                        $(form).prepend('<div class="message-was-sent">Dziękujemy, wiadomość została wysłana.</div>');
                    } else {
                        $(form).prepend('<div class="message-was-not-sent">Przepraszamy. wiadomość nie została wysłana</div>');
                    }

                    formData.forEach(function (field) {
                        if ($(`form [name="${field.name}"]`)) {
                            $(`form [name="${field.name}"]`).removeClass('is-valid');
                            $(`form [name="${field.name}"]`).removeClass('is-invalid');

                            if (response.responseJSON.status === 'success') {
                                if ($(`form [name="${field.name}"]`).attr('type') === 'checkbox') {
                                    $(`form [name="${field.name}"]`).prop('checked', false);
                                } else {
                                    $(`form [name="${field.name}"]`).val('');
                                }
                            }
                        }
                    });
                }
            });
        }
    });
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
